/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

@font-face {
  font-family: "MuseoSans";
  src: url("assets/fonts/MuseoSans/MuseoSans-100.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSans300";
  src: url("assets/fonts/MuseoSans/MuseoSans-300.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSans";
  src: url("assets/fonts/MuseoSans/MuseoSans-100.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansBold";
  src: url("assets/fonts/MuseoSans/MuseoSans-700.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MuseoSansBold500";
  src: url("assets/fonts/MuseoSans/MuseoSans-500.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "MuseoSans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "MuseoSansBold", sans-serif !important;
  margin: 0px !important;
}

.title-modal {
  font-family: "MuseoSansBold" !important;
  font-style: normal;
  font-weight: normal;
}

.subtitle-modal {
  font-family: "MuseoSans300" !important;
  font-style: normal;
  font-weight: normal;
}

.btn,
.form-control,
p,
a {
  font-family: "MuseoSans", sans-serif !important;
}

.font-primary-color {
  color: #00405c;
}

.font-secondary-color {
  color: #00b3ff;
}

.font-alterna-color {
  color: #c9f0ff;
}

.primary-color {
  background-color: #00405c;
}

.secondary-color {
  background-color: #00b3ff;
}

.wrap-mat-radio-label {
  white-space: normal;
}

.mat-stepper-horizontal-line {
  border-top-width: 2px;
}

.mat-step-header .mat-step-icon {
  background-color: #e4e4e4;
  color: #707070;
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(61, 179, 255, 0.6);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #00b3ff;
  color: #fff;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-button-toggle-checked {
  color: #00b3ff;
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: none;
}

.mat-radio-outer-circle,
.mat-radio-checked {
  border-color: #00b3ff;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: #00405c !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #00b3ff !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #00b3ff !important;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.btn:focus,
.btn .focus {
  box-shadow: none !important;
  outline: none !important;
}

.mat-button-toggle-label-content {
  padding: 0px !important;
  margin-right: 24px;
}

.btn-modal-margin {
  margin-top: 20px !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #00b3ff;
  animation: spinner 0.8s linear infinite;
}

.mat-step-header-ripple {
  background-color: #fff;
}
/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
  padding: 5px;
  background-color: transparent;
  color: #00b3ff;
  font-weight: 700;
}

/* Styles for the ink bar */
.mat-ink-bar {
  background-color: #00b3ff;
}

mat-card {
  margin-top: 3vh;
  width: 80vw;
}

.mat-fab.mat-accent {
  background-color: #00b3ff;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.42) !important;
}

table {
  width: 100%;
}

.mat-cell {
  color: #00405c;
}

.table-header-color {
  background-color: #f7f7f7;
  color: #00405c;
}

.paginator {
  background-color: #f7f7f7;
  color: #00405c;
}

.steper-payment > .mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-form-field-underline {
  width: 0 !important;
}

.contact-dialog-container .mat-dialog-container {
  padding: 0px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
}

.mat-horizontal-stepper-header {
  background-color: transparent !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 0px !important;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
.form-control {
  color: #00405c !important;
  font-family: "MuseoSansBold" !important;
}

.mat-expansion-panel-body {
  padding: 35px 16px !important;
}

.btn {
  box-shadow: none !important;
  font-family: "MuseoSansBold" !important;
  font-weight: 400;
}

.mat-raised-button {
  padding: 3px 35px !important;
}

.mat-dialog-content {
  /* height: auto !important; */
  max-height: 680px !important;
  margin: 0px !important;
  padding: 0 12px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.mat-dialog-container {
  padding: 30px !important;
}

.control-label.required:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 5px;
  top: 7px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989898;
  opacity: 1; /* Firefox */
  font-weight: normal !important;
  font-family: "MuseoSans300" !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #989898;
  opacity: 1;
  font-weight: normal !important;
  font-family: "MuseoSans300" !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #989898;
  font-weight: normal !important;
  font-family: "MuseoSans300" !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #989898;
  font-weight: normal !important;
  font-family: "MuseoSans300" !important;
}

.btn-template {
  color: #fff;
  border: 1px solid #fff;
}

.btn-template:hover {
  color: #fff;
}

.navbar-brand:hover {
  cursor: pointer !important;
}

.ant-picker-cell-week {
  display: none !important;
}

.ant-picker-content th:nth-child(1) {
  display: none !important;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #00405c;
}

.ant-picker-week-panel-row:hover td {
  background: #00b3ff !important;
}

.mat-riple {
  width: 0px !important;
  height: 0px !important;
}

.mat-button-toggle .mat-button-toggle-ripple {
  width: 0px !important;
  height: 0px !important;
}

.padding-footer-experimental {
  padding-top: 60px !important;
  padding-left: 50px;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .mat-dialog-content {
    max-height: 90vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .btn-modal-margin {
    margin-top: 10px !important;
  }

  .mat-dialog-container {
    padding: 10px !important;
  }

  .padding-footer-experimental {
    padding-top: 60px !important;
    padding-left: 15px;
  }
}

.mat-horizontal-content-container {
  padding: 0 15px 15px 15px !important;
}

@media only screen and (max-width: 290px) {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
}

div.pac-container {
  z-index: 99999999999 !important;
  position: fixed !important;
}

.scrollable-lg-content{
    overflow-y: auto;
    max-height: calc(100vh - 260px) !important;
    overflow-x: hidden;
}

/* Swal dialogs css*/

.confirm-btn {
  background-color: #00b3ff !important;
}

.confirm-btn:hover{
  background-image: none !important;
}

.deny-btn{
  color: #00b3ff !important;
  background-color: white !important;
  border-color: white !important;
}

.deny-btn:hover {
  background-image: none !important;
  color: #212529 !important;
  background-color: rgb(245, 245, 245) !important;
}

#swal2-title {
  font-family: "MuseoSansBold" !important;
  font-weight: 400 !important;
  font-size: 1.25rem !important;
  color: #00405c !important;
}

.bold {
  font-weight: 400 !important;
  font-family: "MuseoSansBold" !important;
}

@import "../node_modules/alertifyjs/build/css/alertify.min.css";
@import "../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";
